import slick from "slick-carousel";

jQuery( document ).ready(function($) {

  // mobile menu
  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('.menu-menu-container').toggleClass('active')
  });

  // sliders
  $('.slider-offices').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev"><img src="../../wp-content/themes/beggars-global-45p/dist/img/arrow-left.png"></button>',
    nextArrow: '<button type="button" class="slick-next"><img src="../../wp-content/themes/beggars-global-45p/dist/img/arrow-right.png"></button>',
  });

  $('.accordion-trigger').click(function(e){
    e.preventDefault()
    $(this).toggleClass('active')
    $(this).parent().parent().find('.accordion-content').slideToggle()
  });

});